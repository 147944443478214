const menuItemsWithChildren = document.querySelectorAll('.menu-item-has-children');

menuItemsWithChildren.forEach(menuItem => {
    const menuItemLink = menuItem.querySelector('a');

    const openerDiv = document.createElement('div');
    openerDiv.classList.add('header-nav__opener', 'js-submenu-toggle');

    const img = document.createElement('img');
    img.src = '/wp-content/themes/me/dist/images/arrow-bottom-blue.svg';
    img.alt = 'arrow';
    img.width = '12';
    img.height = '7';

    openerDiv.appendChild(img);

    menuItemLink.insertAdjacentElement('afterend', openerDiv);
});

const menuItemsWithSubmenu = document.querySelectorAll('.menu-item-has-children > ul');

menuItemsWithSubmenu.forEach(submenu => {
    const submenuLinks = submenu.querySelectorAll('a');

    submenuLinks.forEach(link => {
        const spanElement = document.createElement('span');
        link.insertBefore(spanElement, link.firstChild);
    });
});
