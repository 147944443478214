/**
 * Tabs
 * ======================================
 * - toggle class on tab nav item
 * - toggle hidden attribute on tabs
 * - html markup required (js-tabs-select, js-tabs-nav, js-tabs-list)
 * - support for mobile select
 * - support multiple widgets
 */

const WIDGET = '.js-tabs-vocabulary'
const ACTIVECLASS = 'current'

class Tabs {
  constructor() {
    this.elements = document.querySelectorAll(WIDGET)

    this.elements.forEach((el) => {
      const nav = el.querySelectorAll('.js-tabs-nav-vocabulary > *')
      const select = el.querySelector('.js-tabs-select-vocabulary')

      nav.forEach((el) => {
        el.addEventListener('click', this.clickHandler, false)
      })

      if(select){
        select.addEventListener('change', this.selectHandler, false)
      }
    })
  }

  clickHandler = (e) => {
    const button = e.currentTarget
    const widget = button.closest(WIDGET)

    this.switchTab(widget, this.getIndex(button))
    button.classList.add(ACTIVECLASS)
  }

  selectHandler = (e) => {
    const select = e.currentTarget
    const widget = select.closest(WIDGET)

    this.switchTab(widget, select.selectedIndex)
  }

  switchTab = (widget, index) => {
    const tabs = widget.querySelectorAll('.js-tabs-list-vocabulary > *')

    this.reset(widget)

    tabs.forEach((tab) => {
      if (this.getIndex(tab) === index) {
        tab.removeAttribute('hidden')
      }
    })

    window.dispatchEvent(new CustomEvent('scroll'))
  }

  getIndex = (el) => {
    return [...el.parentNode.children].indexOf(el)
  }

  reset = (widget) => {
    const nav = widget.querySelectorAll('.js-tabs-nav-vocabulary > *')
    const tabs = widget.querySelectorAll('.js-tabs-list-vocabulary > *')

    nav.forEach((button) => {
      button.classList.remove(ACTIVECLASS)
    })

    tabs.forEach((tab) => {
      tab.setAttribute('hidden', 'hidden')
    })
  }
}

new Tabs()
