document.addEventListener('DOMContentLoaded', function() {
    var toggles = document.querySelectorAll('.js-submenu-toggle');
    toggles.forEach(function(toggle) {
        toggle.addEventListener('click', function() {
            var listItem = this.closest('li');
            if (listItem) {
                listItem.classList.toggle('subnav-is-open');
            }
        });
    });
});
