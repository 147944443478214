document.addEventListener('DOMContentLoaded', function() {
    var formItems = document.querySelectorAll('.form__item');

    if (formItems.length > 0) {
        var inputs = document.querySelectorAll('input, textarea, select');

        function toggleActiveClass(input) {
            var parentItem = input.closest('.form__item');
            if (parentItem) {
                if (input.value.trim() !== '' || input === document.activeElement) {
                    parentItem.classList.add('is-active');
                } else {
                    parentItem.classList.remove('is-active');
                }
            }
        }

        inputs.forEach(function(input) {
            toggleActiveClass(input);

            input.addEventListener('input', function() {
                toggleActiveClass(input);
            });

            input.addEventListener('focus', function() {
                toggleActiveClass(input);
            });

            input.addEventListener('blur', function() {
                toggleActiveClass(input);
            });
        });
    }
});
