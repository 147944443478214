/**
 * Accordion
 * ======================================
 * - toggle class on parent
 */

const ELEMENTS = ".js-accordion .js-accordion-header";
const TOGGLE_CLASS = "is-open";

class Accordion {
  constructor() {
    this.elements = document.querySelectorAll(ELEMENTS);

    if (!this.elements) {
      return false;
    }

    this.elements.forEach((el) => {
      el.addEventListener("click", this.toggle);
    });
  }

  toggle = (e) => {
    const el = e.currentTarget;
    const target = el.parentNode;

    target.classList.toggle(TOGGLE_CLASS);

    e.preventDefault();
  };
}

new Accordion();
